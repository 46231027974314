<template>
  <div class="text-success">
    <div class="success-message">
      <check-circle :size="80"></check-circle>
      <div class="title-wrapper">
        <div class="title">Thank you! You're in good hands.</div>
        <div class="subtitle">{{ subTitle }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckCircle from 'vue-material-design-icons/CheckCircle';

export default {
  props: {
    subTitle: String,
  },
  components: {CheckCircle},
};
</script>

<style lang="scss" scoped>
.text-success {
  max-width: 500px;
  color: $mcr-tan;
  display: flex;
  flex-direction: column;

  img {
    border-radius: 4px;
    box-shadow: $box-shadow;
    display: block;
    margin-top: 36px;
  }

  .success-message {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title-wrapper {
      margin-top: 24px;
      text-align: center;

      .title {
        font-size: 32px;
      }
    }
  }
}
</style>
