<template>
  <div class="input-field">
    <input v-bind="$attrs" ref="input" />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<style lang="scss" scoped>
.input-field {
  margin: 15px 0;
  width: 100%;
  input {
    width: 100%;
  }
}
</style>
