export const FIELD_NAMES = {
  fullname: 'fullname',
  email: 'email',
  interests: 'interests',
  comments: 'comments',
  uploadFiles: 'upload',
  ancestorFields: 'ancestorfields',
  input: 'input',
};

export const FORM_TYPES = {
  chat: 'chat',
  simple: 'simple',
  bookcall: 'bookcall',
  ancestor: 'ancestor',
  ancestortranslation: 'ancestor-translation',
  tshirtwaitlist: 't-shirt-waitlist',
  tshirtwaitlistUpdate: 't-shirt-waitlist-update',
};
export const PAYMENT_SOURCES = {
  lineage: 'lineage',
};
