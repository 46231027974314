<template>
  <div class="contact-us-page">
    <div class="contact-us-content" :class="{'mobile-reverse': showTextSuccess}">
      <div class="contact-form-wrapper">
        <div class="readable_content form_content contact-form-content">
          <div class="contact-form">
            <div v-if="disclaimer" class="disclaimer">{{ disclaimer }}</div>
            <template v-else>
              <h4 class="title">{{ title }}</h4>
              <p v-if="subtitle">{{ subtitle }}</p>
            </template>
            <contact-us-form-container
              :type="contactFormType"
              :init-data="initData"
              :is-payment="!!paymentSource"
              @submit="onSubmit"
              @form-invalid="onFormInvalid"
            />
            <div class="privacy-disclaimer">
              By submitting this form you agree to our
              <router-link :to="{name: 'terms'}" target="_blank">terms</router-link> and
              <router-link :to="{name: 'privacy-policy'}" target="_blank">privacy policy</router-link>.
            </div>
          </div>
        </div>
      </div>
      <text-success v-if="showTextSuccess" :sub-title="textSuccessSubTitle"></text-success>
      <contact-testimonial v-else />
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import TextSuccess from '@/components/modules/contactUs/components/TextSuccess';
import {FORM_TYPES, PAYMENT_SOURCES} from '@/components/modules/contactUs/constants';

import ContactTestimonial from '../../common/ContactUsTestimonial';
import ContactUsFormContainer from './ContactUsFormContainer';

export default {
  metaInfo: {
    title: 'Contact Us',
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.setInitData(from, vm);
    });
  },
  data() {
    return {
      initData: {},
      submitted: false,
      interestsByRoute: {
        adoption: () => ['connect'],
        translation: () => ['translate-records'],
        probate: () => ['find-records', 'connect'],
        'search-tool-zupu-detail': () => ['translate-records'],
        travel: () => ['plan-travel'],
      },
    };
  },
  computed: {
    ...mapGetters(['contactUsFormState', 'featureSwitchesState', 'contactUsFormInitDataState']),
    formNotEmpty() {
      return (
        this.contactUsFormState.email ||
        this.contactUsFormState.otherComment ||
        this.contactUsFormState.attachments.length ||
        this.contactUsFormState.interests.length
      );
    },
    contactFormType() {
      return this.$route.query.type;
    },
    paymentSource() {
      return this.$route.query.payment;
    },
    disclaimer() {
      const typeMapping = {
        [PAYMENT_SOURCES.lineage]:
          'To receive your lineage, please provide the names of your family. We need these details to match them against our database of records!',
      };
      return typeMapping[this.paymentSource];
    },
    bookCallInviteeEmail() {
      return this.$route.query.invitee_email;
    },
    isBookCall() {
      return this.contactFormType === FORM_TYPES.bookcall;
    },
    title() {
      const mapping = {
        [FORM_TYPES.bookcall]: 'Thanks, we look forward to talking!',
        [FORM_TYPES.ancestortranslation]: 'Tell us about your ancestor',
        [FORM_TYPES.tshirtwaitlistUpdate]: "Anything else you'd like to tell us?",
      };
      return mapping[this.contactFormType] || "Let's talk!";
    },
    subtitle() {
      if (this.isBookCall) {
        return 'Please provide some context before we chat.';
      }
      if (this.paymentSource || this.contactFormType === FORM_TYPES.tshirtwaitlistUpdate) {
        return '';
      }
      return 'No matter where you are in your roots journey, our advisors are here to help you figure out the next steps.';
    },
    showTextSuccess() {
      return !!this.textSuccessSubTitle;
    },
    textSuccessSubTitle() {
      if (this.paymentSource) {
        return "We've sent a payment confirmation to your email.";
      }
      if (this.contactFormType === FORM_TYPES.tshirtwaitlistUpdate) {
        return "We've sent an email confirming you've joined the waitlist";
      }
    },
  },
  methods: {
    onSubmit(data) {
      this.submitted = true;
      const query = this.isBookCall ? {from: FORM_TYPES.bookcall} : {};

      if (
        data &&
        data.response &&
        data.response.entry_point_url &&
        data.response.entry_point_url.includes('/adoption')
      ) {
        query.adoption = 'true';
      }
      this.$router.push({name: 'get-in-touch-success', query});
    },
    onFormInvalid() {
      this.$scrollTo('.error', {offset: -150});
    },
    setInitData(from, vm) {
      vm.initData = vm.getInitData(from, vm);
    },
    getInitData(from, vm) {
      const interests =
        from && from.name && this.interestsByRoute[from.name] ? vm.interestsByRoute[from.name](from) : [];
      const mapping = {
        [FORM_TYPES.ancestortranslation]: {ancestor: {relation: 'Grandfather'}},
      };
      const dataByFormType = mapping[this.contactFormType] || {};
      const data = {interests, ...dataByFormType};
      if (vm.contactUsFormInitDataState) {
        const stateData = vm.contactUsFormInitDataState;
        this.$store.commit('setContactUsFormInitDataState', {});
        return {...data, ...stateData};
      }
      return data;
    },
  },
  components: {TextSuccess, ContactUsFormContainer, ContactTestimonial},
  name: 'ContactUsPage',
};
</script>

<style lang="scss" scoped>
.contact-us-page {
  display: flex;
  justify-content: center;
  .contact-us-content {
    padding: $desktop-content-padding;
    display: flex;
    align-items: center;
    flex-grow: 1;
    max-width: $content-max-width;

    .contact-testimonial {
      flex-grow: 1.5;
      margin-left: 50px;
    }
    .text-success {
      margin-left: 70px;
    }
    .contact-form-wrapper {
      flex-grow: 1;
      display: flex;

      .contact-form-content {
        background-color: $background-alternate-color;
        border-radius: 8px;
        box-shadow: $box-shadow-extra-light;

        .title {
          margin-bottom: 16px;
          font-weight: normal;
        }
        .disclaimer {
          color: $secondary-text-color;
          border-bottom: 1px solid $divider-line-color;
          padding-bottom: 20px;
          margin-bottom: 20px;
        }
        .privacy-disclaimer {
          font-style: italic;
          margin-top: 10px;
          font-size: 0.8em;
          color: $supplemental-text-color;
          a {
            color: $supplemental-text-color;
            text-decoration: underline;
          }
        }
      }
    }

    @media only screen and (max-width: $main-menu-breakpoint) {
      flex-direction: column;
      .contact-testimonial,
      .text-success {
        margin: 50px 0;
      }
      &.mobile-reverse {
        flex-direction: column-reverse;
        .text-success {
          margin-top: 0;
        }
      }
    }

    @media only screen and (max-width: $breakpoint-phablet) {
      padding: $mobile-se-content-padding;
      padding-top: 50px;
    }
  }
}
</style>
