<template>
  <div class="contact-testimonial">
    <div class="testimonial-block">
      <p class="quote">
        Even though I am a professional historian myself, I never could have done this kind of research on my own.
        <span>Thank you, My China Roots!</span>
      </p>
      <div class="author-block">
        <img :src="$getAsset('/assets/services/testimonials/ErikaLeeweb.jpeg')" width="130" height="130" />
        <p class="author">
          <strong>Erika Lee</strong><br />
          Author, <em>The Making of Asian America</em>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
$testimonial-text-color: #555;
.contact-testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $testimonial-text-color;

  .testimonial-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 400px;

    .quote {
      position: relative;
      width: 320px;
      margin: 0 10px;
      margin-bottom: 20px;
      span {
        display: block;
      }
      &::before,
      &::after {
        font-family: arial;
        font-size: 3.6em;
        line-height: $text-size;
        position: absolute;
        color: $mcr-light-grey;
      }
      &::before {
        content: '\201C';
        left: -6px;
        top: 5px;
      }
      &::after {
        content: '\201D';
        right: 5px;
        bottom: -25px;
      }
    }
    .author-block {
      margin: 0 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        margin-top: 16px;
        margin-bottom: 16px;
        box-shadow: $box-shadow;
        border-radius: 50%;
        width: 130px;
        object-fit: cover;
      }
      .author {
        color: $testimonial-text-color;
        strong {
        }
      }
    }
  }
}

@media only screen and (max-width: $main-menu-breakpoint) {
  .contact-testimonial {
    margin-right: 0;
    margin-bottom: 20px;
    .testimonial-block {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      width: auto;
      .author-block {
        flex-grow: 0;
        .author {
          margin-top: 0;
          font-size: 0.8em;
        }
      }
      @media only screen and (max-width: $breakpoint-mobile-se) {
        .quote {
          width: auto;
          span {
            display: inline;
          }
          &::before {
            top: -5px;
            left: -5px;
          }
          &::after {
            bottom: -40px;
            right: 5px;
          }
        }
      }
    }
  }
}
</style>
